<template>
  <div class="box">
    <div v-show="msgInfo.isDeleted == 0">
      <div class="top">
        <img class="one" src="../assets/image/3.png" alt="" />
        <img class="two" src="../assets/image/2.png" alt="" />
        <!-- <div class="box1">
        <div class="iconbox">
          <div class="icon"></div>
          <div class="dashed"></div>
          <div class="icon"></div>
          <div class="dashed"></div>
          <div class="icon"></div>
          <div class="dashed"></div>
          <div class="icon"></div>
        </div>
        <div class="right">
          <div class="ul uls">
            <div class="title">开始尝鲜</div>
            <div class="text">9月23日【秋分】农历八月廿八</div>
          </div>
          <div class="ul uls">
            <div class="title">初尝母蟹</div>
            <div class="text">10月8日【寒露】农历九月十三</div>
          </div>
          <div class="ul uls">
            <div class="title">母蟹最肥</div>
            <div class="text">10月23日【霜降】农历九月廿八</div>
          </div>
          <div class="ul uls">
            <div class="title">开始尝鲜</div>
            <div class="text">11月7日【立冬】农历十月十四</div>
          </div>
        </div>
      </div> -->
        <img style="height: 69vw; width: 65vw; margin: 25px 0 20px 20px" src="../assets/image/8.png" alt="" />
        <div class="box2">
          <input type="text" v-model="ticketNo" placeholder="请输入蟹券编号" />
          <input type="password" v-model="ticketPasswd" placeholder="请输入蟹券密码" />
          <div class="yzm">
            <input v-model="yzm" type="text" placeholder="请输入验证码" />
            <canvas id="s-canvas" @click="upcode" :width="contentWidth" :height="contentHeight"></canvas>
          </div>
          <div class="but" @click="submit">立即查询</div>
        </div>
      </div>
      <div class="bom">
        <img src="../assets/image/1.png" alt="" />
        <img class="right" src="../assets/image/5.png" alt="" />
      </div>
      <img class="kf" src="../assets/image/6.png" alt="" @click="show = true" />
      <van-action-sheet v-model="show">
        <van-picker title="客服列表" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="show = false">
          <template #title>
            <div style="display: flex; flex-direction: column; align-items: center">
              <div style="
                color: #333;
                font-size: 18px;
                font-weight: 600;
                margin: 5px 0;
              ">
                客服列表
              </div>
              <div style="color: #999; font-size: 12px">工作时间：09点~17点</div>
            </div>
          </template>
          <template #confirm>
            <div style="color: #387aff">拨号</div>
          </template>
        </van-picker>
      </van-action-sheet>
    </div>
    <div v-show="msgInfo.isDeleted != 0" style="color: #fff;padding: 50px 20px 0 20px;box-sizing: border-box;">{{msgInfo.messageTitle}}</div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getCrabTicket, getMsgTitle } from '../api/user'
export default {
  data() {
    return {
      columns: ['4008861797', '027-88013866', '027-88011709'],
      show: false,
      fontSizeMin: 25,
      fontSizeMax: 35,
      backgroundColorMin: 200,
      backgroundColorMax: 220,
      dotColorMin: 60,
      dotColorMax: 120,
      contentWidth: 70,
      contentHeight: 40,
      ticketNo: '',
      ticketPasswd: '',
      identifyCode: '',
      yzm: '',
      msgInfo: {}
    }
  },
  watch: {
    identifyCode() {
      this.drawPic()
    }
  },
  created() {
    this.makeCode('1234567890', 4)
    this.onGetMsgTitle()
  },
  mounted() {
    this.drawPic()
  },
  methods: {
    onGetMsgTitle() {
      getMsgTitle().then(res => {
        this.msgInfo = res.data.data
      })
    },
    onConfirm(e) {
      this.show = false
      window.location.href = 'tel:' + e
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 生成一个随机的颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max)
      const g = this.randomNum(min, max)
      const b = this.randomNum(min, max)
      return 'rgb(' + r + ',' + g + ',' + b + ')'
    },
    drawPic() {
      const canvas = document.getElementById('s-canvas')
      const ctx = canvas.getContext('2d')
      ctx.textBaseline = 'bottom'
      // 绘制背景
      ctx.fillStyle = this.randomColor(
        this.backgroundColorMin,
        this.backgroundColorMax
      )
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight)
      // 绘制文字
      for (let i = 0; i < this.identifyCode.length; i++) {
        this.drawText(ctx, this.identifyCode[i], i)
      }
      this.drawLine(ctx)
      this.drawDot(ctx)
    },
    drawText(ctx, txt, i) {
      ctx.fillStyle = this.randomColor(50, 160) // 随机生成字体颜色
      ctx.font =
        this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei' // 随机生成字体大小
      const x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1))
      const y = this.randomNum(this.fontSizeMax, this.contentHeight - 5)
      var deg = this.randomNum(-30, 30)
      // 修改坐标原点和旋转角度
      ctx.translate(x, y)
      ctx.rotate((deg * Math.PI) / 180)
      ctx.fillText(txt, 0, 0)
      // 恢复坐标原点和旋转角度
      ctx.rotate((-deg * Math.PI) / 180)
      ctx.translate(-x, -y)
    },
    drawLine(ctx) {
      // 绘制干扰线
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = this.randomColor(100, 200)
        ctx.beginPath()
        ctx.moveTo(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight)
        )
        ctx.lineTo(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight)
        )
        ctx.stroke()
      }
    },
    drawDot(ctx) {
      // 绘制干扰点
      for (let i = 0; i < 30; i++) {
        ctx.fillStyle = this.randomColor(0, 255)
        ctx.beginPath()
        ctx.arc(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight),
          1,
          0,
          2 * Math.PI
        )
        ctx.fill()
      }
    },
    upcode() {
      this.identifyCode = ''
      this.makeCode('1234567890', 4)
      this.drawPic()
    },
    async submit() {
      if (!this.yzm) {
        Toast('请填写验证码')
        return
      } else if (this.yzm !== this.identifyCode) {
        Toast('验证码输入有误')
        this.yzm = ''
        this.identifyCode = ''
        this.makeCode('1234567890', 4)
        this.drawPic()
        return
      }
      const { data } = await getCrabTicket({
        ticketNo: this.ticketNo,
        ticketPasswd: this.ticketPasswd
      })
      console.log(data)
      if (data.code === 200) {
        if (data.data.exchangeStatus === 1 && data.data.sendStatus === 0) {
          Toast('暂未发货!')
          this.yzm = ''
          this.identifyCode = ''
          this.makeCode('1234567890', 4)
          this.drawPic()
          return
        }
        if (data.data.exchangeStatus === 0) {
          this.$router.push(
            `/details?id=${data.data.ticketNo}&pass=${data.data.ticketPasswd}`
          )
        } else {
          this.$router.push(
            `/information?id=${data.data.ticketNo}&pass=${data.data.ticketPasswd}`
          )
        }
      } else {
        Toast(data.msg)
        this.yzm = ''
        this.identifyCode = ''
        this.makeCode('1234567890', 4)
        this.drawPic()
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-picker__toolbar {
  height: 55px;
}

.kf {
  width: 70px;
  height: 70px;
  position: fixed;
  right: -10px;
  bottom: 20vh;
}

.box {
  border: none;
  min-height: 100vh;
  background-color: #6a201d;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.box1 {
  width: 80%;
  display: flex;
  margin-top: 45px;
  margin-left: 10px;
}

.right {
  color: #dfab72;

  .ul {
    height: 70px;
  }

  .title {
    font-size: 17px;
  }

  .text {
    font-size: 14px;
  }
}

.top {
  .one {
    width: 25px;
    position: absolute;
    top: 30px;
    left: 15px;
  }

  .two {
    width: 25px;
    position: absolute;
    right: 15px;
    bottom: 50px;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 84vw;
  margin-left: 8vw;
  margin-top: 30px;
  background-image: url('../assets/image/14.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .iconbox {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3px;

    .icon {
      background-color: #fff;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      border: 3px solid #dfab72;
    }

    .dashed {
      height: 55px;
      border-left: 1px dashed #dfab72;
    }
  }
}

.bom {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;

  img {
    height: 58vw;
    width: 58vw;
  }

  .right {
    width: 40vw;
    height: 48vw;
  }
}

.box2 {
  width: 80%;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  margin-right: 28px;

  .but {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background-color: #dfab72;
    font-size: 15px;
    border-radius: 40px;
    margin-bottom: 30px;
  }
}

input {
  border: none;
  height: 40px;
  border: 1px solid #ee3e00;
  margin-bottom: 15px;
  border-radius: 40px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.yzm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 15px;

  input {
    width: 68%;
    margin-bottom: 0%;
  }

  div {
    width: 28%;
    height: 40px;
    background-color: rgb(155, 151, 151);
  }
}
</style>
